@media screen and (min-width:425px) {
    .bookImage {
        margin-left: 50px;
    }

}

@media screen and (max-width:1024px) {
    .leftMobileContent {
        right: 24%;

    }

    .rightMobileContent {
        left: 22%;

    }
}

@media screen and (max-width:992px) {
    .log_img{
        margin-left: 22px !important;
    }
    .log_img img{
        width: 47% !important;
    }
    .footerPadding {
        padding-inline: 2rem;
    }

    .donate__style {
        border-radius: 25px;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-inline: 1rem;
        padding: 1rem;
    }

    .mobil__img {
        width: 50%;
        height: 100%;
    }

    .mobile__title {
        font-size: 1rem;
    }

    small,
    .small {
        font-size: 0.75em;
    }

    .leftMobileContent {
        top: 64%;
        rotate: -12deg;
        right: 35%;

    }

    .rightMobileContent {
        top: 63%;
        rotate: 15deg;
        left: 35%;

    }

    .testimonialBorderDown {
        width: 40% !important;
    }
}

@media screen and (max-width:320px) {
    .testimonialBorderDown {
        width: 96% !important;
    }
}

@media screen and (max-width:1024px) {
    .testimonialBorderDown {
        width: 34% !important;
    }
}

@media screen and (max-width:425px) {
    .mobil__img {
        width: 80%;
        height: 100%;
    }

    .mobile__title {
        font-size: 0.70rem;
        margin: 0;
    }

    small,
    .small {
        font-size: 0.60em;
    }

    .leftMobileContent {
        top: 64%;
        rotate: -12deg;
        right: 31%;

    }

    .white_line {
        display: none;
    }

    .rightMobileContent {
        top: 63%;
        rotate: 15deg;
        left: 25%;

    }

    .testimonialBorderDown {
        width: 81% !important;
    }
}