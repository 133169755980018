* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.greyBackground {
    background-color: #f5f5f5;
}

.menus {
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items: center;
    margin-right: 20px;
}
.activeNavLink{
    color: red;
}
.navlink:hover{
    color: #257531;
}
.navlink{
    font-weight: 600;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.common_btn {
    background: linear-gradient(to bottom, #257531, #00668A);
    color: white;
    height: 42px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 10px;
}
.common_btn:hover{
    background: linear-gradient(to bottom, #00668A, #257531);
    color: #000;

}

.section1Backimg {
    position: relative;
    height: 75vh;
    background-image: url('../assets/images/section1Back.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    position: absolute;
    top: 35%;
    right: 15%;
    color: white;
}

.bookImage {
    margin-left: 80px;
}

.mobileImages {
    position: absolute;
}

.donateContent {
    position: absolute;
    top: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.donateBtn {
    color: #116D63;
    background-color: #e4f5ef;
    font-size: 1.6rem;
    height: 50px;
    font-weight: 700;
    padding: 5px 25px;
}

.footerImage {
    height: 400px;
    background-image: url('../assets/images/Footer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 120px;
    display: flex;
    align-items: center;
}

.white_line {
    border-right: 2px solid white;
    width: 3%;
    height: 50px;
}

.leftMobileContent {
    position: absolute;
    top: 66%;
    rotate: -12deg;
    right: 25%;

}

.rightMobileContent {
    position: absolute;
    top: 66%;
    rotate: 15deg;
    left: 26%;

}

.footerPadding {
    padding-inline: 8rem;
    width: 100%;
}

.donate__style {
    background-image: url('../assets/images/donateBack.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    min-height: 250px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-inline: 2rem;
}

.mobil__img {
    width: 80%;
    height: 100%;
}

.mobile__title {
    font-size: 1.25rem;
}

.testimonilaMain {
    padding: 2rem;

}
.testimonial_heading{
    font-weight: 600;
}

.testimonialBorderDown {
    width: 28%;
    border-radius: 10px;
    height: 5px;
    background-color: red;
    background: linear-gradient(to left, #257531, #00668A);
}
.slider_content{
    background: linear-gradient(to bottom, #00668A,#257531);
    color: white;
    /* height: 300px; */
    width: 31%;
    text-align: center;
    border-radius: 32px;
    position: relative;
    padding: 10px;
}
.slider_content:hover{
    background: linear-gradient(to bottom, #257531,#00668A);

}
.circleImage{
    /* position: absolute;
    bottom: 87%;
    right: 39%; */
    display: flex;
    justify-content: center;
    margin-bottom: -40px;
    z-index: 1;
    position: relative;
}
.solidAppLink{
    color: white;
    text-decoration: none;
}
.solidAppLink:hover{
    text-decoration: underline;
}